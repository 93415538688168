<template>
  <div class="one-device-page x-background-color x-box-shadow">
    <x-table
      new-style
      :no-data-text="CA('device_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    >
      <template #footer>
        <div class="footer">
          <Page
            :current="page.pageNo"
            :page-size="page.pageSize"
            :total="page.total"
            :show-elevator="true"
            :show-total="true"
            @on-change="pageChange"
          ></Page>
        </div>
      </template>
    </x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <FormItem label="一体机名称" prop="name">
          <Input v-model="form.name" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="一体机设备key" prop="deviceKey">
          <Input v-model="form.deviceKey" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="是否为虚拟机" prop="isFictitious">
          <Select v-model="form.isFictitious" style="width: 300px">
            <Option :value="2">是</Option>
            <Option :value="1">否</Option>
          </Select>
        </FormItem>
        <FormItem label="所属基地" prop="farm">
          <Select v-model="form.farm" clearable style="width: 300px">
            <Option
              v-for="(item, index) in farmArr"
              :key="index"
              :value="`${item.id}/${item.name}`"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="所属场景" prop="scene">
          <Select
            v-model="form.scene"
            clearable
            multiple
            style="width: 300px"
            :disabled="form.farm ? false : true"
            :placeholder="form.farm ? '请选择场景' : '请先选择基地'"
          >
            <Option
              v-for="(item, index) in sceneArr"
              :value="`${item.sceneId}/${item.name}`"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" :loading="modal.submitLoaidng" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <look-detail v-model="lookDetailShow" :detail="detail"></look-detail>
  </div>
</template>

<script>
import { Switch } from "view-design";
import lookDetail from "./lookDetail";
export default {
  name: "",
  components: {
    lookDetail,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "设备名称",
            minWidth: 100,
            key: "name",
          },
          {
            title: "所属基地",
            minWidth: 100,
            key: "farmName",
          },
          {
            title: "设备key",
            width: 120,
            key: "deviceKey",
          },
          {
            title: "连接地址",
            width: 150,
            key: "linkIp",
          },
          {
            title: "连接端口",
            width: 100,
            key: "linkPort",
          },
          {
            title: "更新时间",
            width: 180,
            key: "updateTime",
          },
          {
            title: "创建时间",
            width: 180,
            key: "createTime",
          },
          {
            title: "是否为虚拟机",
            width: 120,
            render: (h, { row }) => {
              return <span>{row.isFictitious == "1" ? "否" : "是"}</span>;
            },
          },
          {
            title: "状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <Switch
                  size="large"
                  true-value={1}
                  false-value={
                    +row.status == 0 ? 0 : +row.status === 2 ? 2 : ""
                  }
                  disabled={
                    +row.status == 1
                      ? this.CA("device_stop")
                        ? false
                        : true
                      : this.CA("device_open")
                      ? false
                      : true
                  }
                  value={+row.status}
                  loading={this.statusLoading}
                  onInput={(val) => this.statusChange(val, row.guid)}
                >
                  <span slot="open">启用</span>
                  <span slot="close">停用</span>
                </Switch>
              );
            },
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("device_update") && (
                    <a
                      class="iconfont"
                      title="编辑"
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("device_view") && (
                    <a
                      class="iconfont"
                      title="查看详情"
                      style="margin-right: 10px"
                      onClick={() => this.look(row)}
                    >
                      &#xe66d;
                    </a>
                  )}
                  {this.CA("device_bk") && (
                    <a
                      class="iconfont"
                      title="板卡管理"
                      style="margin-right: 10px"
                      onClick={() =>
                        this.goThere("boardCfg", {
                          query: { aioGuid: row.guid },
                        })
                      }
                    >
                      &#xe686;
                    </a>
                  )}
                  {this.CA("device_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.guid)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },

      modal: {
        show: false,
        title: "",
        submitLoaidng: false,
      },
      statusLoading: false,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      form: {
        companyNo: localStorage.companyNo,
        name: "",
        deviceKey: "",
        farm: "",
        scene: [],
        remark: "",
        isFictitious: "",
        guid: "",
        id: "", //一体机ID
      },
      rules: {
        name: [{ required: true, message: "请填写一体机名称" }],
        deviceKey: [{ required: true, message: "请填写一体机设备key" }],
        farm: [{ required: true, message: "请选择基地" }],
        scene: [{ required: true, type: "array", message: "请选择场景" }],
        isFictitious: [{ required: true, message: "请选择是否为虚拟机" }],
      },

      farmArr: [],
      sceneArr: [],
      search_data: {
        farmId: "",
        name: "",
      },

      lookDetailShow: false,
      detail: {},
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "添加设备",
            ca: "device_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "设备名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "基地",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farmArr,
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "设备key",
              component: "input",
              field: "deviceKey",
              defaultValue: "",
            },
            {
              conditionName: "是否虚拟机",
              component: "select",
              field: "isFictitious",
              defaultValue: "",
              data: [
                {
                  name: "是",
                  val: 2,
                },
                {
                  name: "否",
                  val: 1,
                },
              ],
              parameterField: "val",
              showField: "name",
            },
          ],
        },

        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "添加一体机",
        submitLoaidng: false,
      };
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    getList() {
      if (!this.CA("device_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DEVICES.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //获取基地列表
    getFarmList() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 999,
      }).then((res) => {
        this.farmArr = res.list;
      });
    },
    //获取所属场景列表
    getSceneList() {
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        farmId: this.form.farm.split("/")[0],
        pageNo: 1,
        pageSize: 999,
      }).then((res) => {
        this.sceneArr = res.list;
      });
    },
    //启动和停用
    statusChange(status, guid) {
      this.statusLoading = true;
      this.$post(
        status == "1" ? this.$api.DEVICES.OPEN : this.$api.DEVICES.STOP,
        {
          guid,
        }
      )
        .then(() => {
          this.$Message.success(status == "1" ? "启用成功" : "停用成功");
          this.getList();
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },

    edit(row) {
      this.form.name = row.name;
      this.form.deviceKey = row.deviceKey;
      this.form.remark = row.remark;
      this.form.guid = row.guid;
      this.form.id = row.id;
      this.form.farm = `${row.farmId}/${row.farmName}`;
      this.form.isFictitious = Number(row.isFictitious);
      let sceneIdArr = row.sceneIds.split(",");
      let sceneNameArr = row.sceneNames.split(",");
      for (let i = 0; i < sceneIdArr.length; i++) {
        let scene = `${sceneIdArr[i]}/${sceneNameArr[i]}`;
        this.form.scene.push(scene);
      }
      this.modal = {
        show: true,
        title: "编辑一体机",
        submitLoaidng: false,
      };
    },

    del(guid) {
      this.$post(this.$api.DEVICES.DELETE, {
        guid,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    look(row) {
      this.lookDetailShow = true;
      this.detail = row;
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        companyNo: localStorage.companyNo,
        name: "",
        deviceKey: "",
        farm: "",
        scene: [],
        remark: "",
        isFictitious: "",
      };
      this.$refs.form.resetFields();
    },

    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.form.farm.split("/")[0];
        params.farmName = this.form.farm.split("/")[1];
        let sceneIdArr = [];
        let sceneNameArr = [];
        this.form.scene.forEach((item) => {
          sceneIdArr.push(item.split("/")[0]);
          sceneNameArr.push(item.split("/")[1]);
        });
        params.sceneIds = sceneIdArr.join(",");
        params.sceneNames = sceneNameArr.join(",");
        delete params.farm;
        delete params.scene;
        this.modal.submitLoaidng = true;
        this.$post(
          params.guid ? this.$api.DEVICES.UPDATE : this.$api.DEVICES.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoaidng = false;
          });
      });
    },

    goThere(router, params) {
      this.$router.push({ name: router, ...params });
    },
  },
  mounted() {
    this.getList();
    this.getFarmList();
  },
  watch: {
    "form.farm"(n, o) {
      if (n === o) return;
      if (!n) {
        this.form.scene = "";
      } else {
        this.getSceneList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.one-device-page {
  width: 100%;
  height: 100%;
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    .search-pane {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 1;
      span {
        padding-right: 16px;
      }
    }
  }
  .footer {
    text-align: right;
    padding: 16px;
  }
}
</style>